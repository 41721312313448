import { createSlice } from '@reduxjs/toolkit';
import {ISlice} from "./ISlice";

const currenciesUrl = "https://rubinchyk.com/newsb/additional/currencies";

const initialState = {
  currencies: []
};

export const currenciesSlice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    currencies_load: state => {
      // state.loading = true;
    },
    currencies_load_success: (state, { payload }) => {
      state.currencies = payload[0];
      // state.loading = false;
      // state.hasErrors = false;
    },
    currencies_load_failure: state => {
      // state.loading = false;
      // state.hasErrors = true;
    },
  }
});

export const { currencies_load, currencies_load_success, currencies_load_failure } = currenciesSlice.actions;

export const currenciesSelector = (state: ISlice) => {
  return state['currenciesSlice'];
};

export default currenciesSlice.reducer;

export function getCurrencies() {
  return async (dispatch: any) => {
    dispatch(currencies_load());

    try {
      const response = await fetch(currenciesUrl);
      const data = await response.json();
      dispatch(currencies_load_success(data));
    } catch (error) {
      dispatch(currencies_load_failure());
    }
  }
}

