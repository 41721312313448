import React, { useEffect } from "react";
import "./AddThis.scss";

function AddThis() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c7c26eb636a6aab";
    script.async = true;

    document.body.appendChild(script);
  });

  return (<div/>);
}

export default AddThis;
