import {createSlice} from '@reduxjs/toolkit';

const getWidgetsDataUrl = "https://rubinchyk.com/newsb/posts/breaking-news";
const getWidgetsHebrewDataUrl = "https://rubinchyk.com/newsb/hebrew/breaking-news";

const initialState = {
    breaking_news_posts: [],
    breaking_news_weather: [],
};

export const widgetsSlice = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        breaking_news_load: state => {
            // state.loading = true;
        },
        breaking_news_load_success: (state, {payload}) => {
            state.breaking_news_posts = payload['posts'];
            state.breaking_news_weather = payload['weather'];
            // state.loading = false;
            // state.hasErrors = false;
        },
        breaking_news_load_failure: state => {
            // state.loading = false;
            // state.hasErrors = true;
        },
    }
});

export const {breaking_news_load, breaking_news_load_success, breaking_news_load_failure} = widgetsSlice.actions;

export const widgetsSelector = (state: any) => {
    return state['widgetsSlice'];
};

export default widgetsSlice.reducer;

export function getWidgetsData(lang: string) {
    const widgetsUrl = (lang === 'russian') ? getWidgetsDataUrl : getWidgetsHebrewDataUrl;

    return async (dispatch: any) => {
        dispatch(breaking_news_load());

        try {
            const response = await fetch(widgetsUrl);
            const data = await response.json();
            dispatch(breaking_news_load_success(data));
        } catch (error) {
            dispatch(breaking_news_load_failure());
        }
    }
}
