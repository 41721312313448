import React from "react";
import "./Footer.scss"

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div>Copyright © {year} rubinchyk.com. All rights reserved.</div>
      <a className="menu-button" href="mailto:alexey@rubinchyk.com">Contact</a>
    </footer>
  );
}

export default Footer;
