import React, {useEffect} from "react";
import "./Currencies.scss";
import {getCurrencies, currenciesSelector} from '../../slices/currenciesSlice'
import {useAppDispatch, useAppSelector} from "../../app/hooks";

function Currencies() {
    const dispatch = useAppDispatch();
    const { currencies } = useAppSelector(currenciesSelector) || [];

    useEffect(() => {
            dispatch(getCurrencies());
    }, [dispatch]);
    return (
      currencies &&
      currencies.length > 0 ?
        <div className="currencies">
            <div className="group">
                <span className="icon">USD</span>&nbsp;&nbsp;
                {currencies["usd"]}
            </div>
            <div className="group">
                <span className="icon">GBP</span>&nbsp;&nbsp;
                {currencies["gbp"]}
            </div>
            <div className="group">
                <span className="icon">EUR</span>&nbsp;&nbsp;
                {currencies["eur"]}
            </div>
        </div> : null
    );
}

export default Currencies;
