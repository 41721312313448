import React, { useEffect, useState } from "react";
import "./Time.scss";
import {ITime} from "./ITime";

export default function Time({ locale }: ITime) {
  let interval: ReturnType<typeof setInterval>;
  const [currentDate, setCurrentDate] = useState(new Date());
  const dd = String(currentDate.getDate()).padStart(2, "0");
  const mm = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = currentDate.getFullYear();
  const today = dd + "/" + mm + "/" + yyyy;

  useEffect(() => {
    interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="datetime">
      <div className="clock">{currentDate.toLocaleTimeString(locale)}</div>
      <div className="date">{today}</div>
    </div>
  );
}
