import { combineReducers } from "redux";
import currenciesSlice from "./currenciesSlice";
import postsSlice from "./postsSlice";
import widgetsSlice from "./widgetsSlice";

const rootReducer = combineReducers({
    currenciesSlice: currenciesSlice,
    postsSlice: postsSlice,
    widgetsSlice: widgetsSlice,
});

export default rootReducer;
