import React from "react";
import { createPortal } from "react-dom";
import "./Loader.scss";
import {ILoaderProps} from "./ILoader";

function Loader({ active }: ILoaderProps) {

  return (
    active ?
      createPortal(
        <div className='preload-wrapper'>
          <div className="preloader loading">
            <span className="slice"></span>
            <span className="slice"></span>
            <span className="slice"></span>
            <span className="slice"></span>
            <span className="slice"></span>
            <span className="slice"></span>
          </div>
        </div>,
        document.body
      ) : null
  );
}

export default Loader;
