import React from "react";
import "./MainHebrew.scss";
import PostsWrapper from "../PostsWrapper/PostsWrapper";
import Footer from "../Footer/Footer";
import BreakingNews from "../BreakingNews/BreakingNews";
import Header from "../Header/Header";


const MainHebrewPage = () => {
  return (
    <div className="Main">
      <Header lang="hebrew"/>

      <PostsWrapper lang="hebrew"/>

      <Footer/>

      <BreakingNews lang="hebrew" />
    </div>
  );
};

export default MainHebrewPage;
