import React from "react";
import "./Post.scss";
import {IPostProps} from "./IPost";

export default function Post({id, link, rating, title, owner, lang, date}: IPostProps) {
  if(!rating) {
    rating = 0;
  }

  const ratingClass = (num: number): string => {
    if (num < 10) {
      return "grey-arrow";
    } else if (num >= 10 && num < 14) {
      return "green-arrow";
    } else if (num >= 14) {
      return "red-arrow";
    } else {
      return "grey-arrow";
      // return "no-vision";
    }
  };

  const getFormattedDate = (date: Date) => {
    date = new Date(date);
    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    let hours = date.getHours() - 1;
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? 0 + minutes : minutes;

    return `${month}/${day}/${year} ${hours}:${minutes}`;
  };

  const sendClickScore =  (e: any) => {
    e.preventDefault();
    const score = {id: e.currentTarget.dataset.id};

    fetch('https://rubinchyk.com/newsb/posts/score/',{
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(score)
    })
    .then(res => res.json())
    .then(data => {
      // do something with data
    })
    .catch(rejected => {
        console.log(rejected);
    });


    window.open((e.currentTarget as HTMLLinkElement).href, "_blank");
  };

  return (
    <a target="_blank" data-id={id} rel="noopener noreferrer" href={link} onClick={sendClickScore}>
      <div className={`post ${ratingClass(rating)}_bg ${(lang === "hebrew" ? "rtl" : "")}`}>
        <div className={`${ratingClass(rating)}`}><span className={"rating"}>{rating}</span></div>
        <div className="post-content">
          <div className="post-title">{title}</div>
          <div className="post-owner">{owner}</div>
          <div className="post-date">{getFormattedDate(date)}</div>
        </div>
        <aside></aside>
      </div>
    </a>
  );
}
