import React from 'react';
import { Routes, Route } from "react-router-dom";
import Main from "./components/Main/Main";
import MainHebrew from "./components/MainHebrew/MainHebrew";
import Description from "./components/Description/Description";
import NotFoundPage from "./components/NotFoundPage";
import './App.scss';

function App() {
  return (
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/he" element={<MainHebrew />} />
          <Route path="/post/:title" element={<Description />} />
          <Route element={<NotFoundPage />} />
        </Routes>
  );
}

export default App;
