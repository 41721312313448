import React, {useEffect} from "react";
import Disqus from "disqus-react";
import "./Description.scss";
import BreakingNews from "../BreakingNews/BreakingNews";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {Helmet} from "react-helmet";
import {getPostDescription, postsSelector} from '../../slices/postsSlice'
import {useAppDispatch, useAppSelector} from "../../app/hooks";

function Description(props: any) {
  const dispatch = useAppDispatch();
  const {posts} = useAppSelector(postsSelector) || [];
  const title = props.match.params.title;

  /**
   * Disqus initialization
   */
  const disqusShortname = "news-israel-com";
  const disqusConfig = {
    url: window.location.href,
    identifier: title,
    title: title,
  };

  useEffect(() => {
    dispatch(getPostDescription(title))
  }, [dispatch]);

  let p = posts;

  const content =
    <div className="post-container" key={p._id}>
      <h1
        className="post-title"
        dangerouslySetInnerHTML={{__html: p.title}}
      />
      <div
        className="post-content"
        dangerouslySetInnerHTML={{__html: p.content}}
      />
      <br/>
      <br/>
      <a href={p.link}>{p.title}</a>
      <div
        className="post-author"
        dangerouslySetInnerHTML={{__html: p.author}}
      />
      <div
        className="post-owner"
        dangerouslySetInnerHTML={{__html: p.owner}}
      />
      <br/>
      <br/>
      <Disqus.DiscussionEmbed
        shortname={disqusShortname}
        config={disqusConfig}
      />
    </div>;

  return (
    <div className="Description">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title}/>
      </Helmet>

      <Header lang="russian"/>

      {content}

      <Footer/>

      <BreakingNews lang="russian"/>
    </div>
  );
}

export default Description;
