import React from "react"
import "./DarkMode.scss"

const DarkMode = () => {
  let clickedClass = "clicked";
  const body = document.body;
  const lightTheme = "light";
  const darkTheme = "dark";
  let theme: string;

  if (localStorage) {
    theme = localStorage.getItem("theme") as string;
  } else {
    theme = lightTheme;
  }

  if (theme === lightTheme || theme === darkTheme) {
    body.classList.add(theme)
  } else {
    body.classList.add(lightTheme)
  }

  const switchTheme = (e: React.SyntheticEvent): void => {
    let target = e.target as HTMLButtonElement;
    if (theme === darkTheme) {
      body.classList.replace(darkTheme, lightTheme);
      localStorage.setItem("theme", "light");
      theme = lightTheme;
    } else {
      body.classList.replace(lightTheme, darkTheme);
      localStorage.setItem("theme", "dark");
      theme = darkTheme;
    }
    target.classList.add(clickedClass);
  };

  return (
    <button
      className={theme === "dark" ? clickedClass : ""}
      id="darkMode"
      onClick={e => switchTheme(e)}
    ></button>
  )
};

export default DarkMode;
