import { createSlice } from '@reduxjs/toolkit';
import _ from "lodash";
import {IPostParams} from "../components/Post/IPost";
const getAllPostsUrl = "https://rubinchyk.com/newsb/posts";
const getAllHebrewPostsUrl = "https://rubinchyk.com/newsb/hebrew";
const getPostUrl = "https://rubinchyk.com/newsb/posts/description/?title=";

const initialState = {
  posts: []
};

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    posts_load: state => {
      // state.loading = true;
    },
    posts_load_success: (state, { payload }) => {
      state.posts = payload;
      // state.loading = false;
      // state.hasErrors = false;
    },
    posts_load_failure: state => {
      // state.posts = [];
      // state.loading = false;
      // state.hasErrors = true;
    },
    posts_description_load: state => {
      // state.loading = true;
    },
    posts_description_load_success: (state, { payload }) => {
      state.posts = payload;
      // state.loading = false;
      // state.hasErrors = false;
    },
    posts_description_load_failure: state => {
      // state.posts = [];
      // state.loading = false;
      // state.hasErrors = true;
    },
  }
});

export const { posts_load, posts_load_success, posts_load_failure, posts_description_load, posts_description_load_success, posts_description_load_failure } = postsSlice.actions;

export const postsSelector = (state: any) => {
  return state['postsSlice'];
};

export default postsSlice.reducer;

const fillEmptyRating = (data: IPostParams[]) => {
  return data.map((post: IPostParams) => {
    if(typeof post.rating === 'undefined') {
      post.rating = 0;
    }
    return post;
  });
};

/**
 * Get all new posts
 * @returns {function(...[*]=)}
 */
export function getMainPosts() {
  return async (dispatch: any) => {
    dispatch(posts_load());

    try {
      const response = await fetch(getAllPostsUrl);
      let postsData = await response.json();

      // Fill values without ratings
      postsData = fillEmptyRating(postsData);

      // Sorting - high rating will be first
      postsData.sort(function(a: IPostParams, b: IPostParams) {
        let keyA = +a.rating,
            keyB = +b.rating;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

      // Remove duplicates
      postsData = _.uniqBy(postsData, (v: IPostParams) => [v.title, v.owner].join());

      // Filtering blocked posts
      postsData = postsData.filter(function(value: IPostParams) {
        return typeof value.blocked == "undefined";
      });

      dispatch(posts_load_success(postsData));
    } catch (error) {
      dispatch(posts_load_failure());
    }
  }
}

/**
 * Get Hebrew posts
 * @returns {function(...[*]=)}
 */
export function getMainHebrewPosts() {
  return async (dispatch: any) => {
    dispatch(posts_load());

    try {
      const response = await fetch(getAllHebrewPostsUrl);
      let postsData = await response.json();

      // Fill values without ratings
      postsData = fillEmptyRating(postsData);

      // Fill values without ratings
      postsData.map((post: IPostParams) => {
        if(typeof post.rating === 'undefined') {
          post.rating = 0;
        }
        return post;
      });

      // Sorting - high rating will be first
      postsData.sort(function(a:IPostParams, b:IPostParams) {
        let keyA = +a.rating,
            keyB = +b.rating;
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });

      // Remove duplicates
      postsData = _.uniqBy(postsData, (v:IPostParams) => [v.title, v.owner].join());

      // Filtering blocked posts
      postsData = postsData.filter(function(value:IPostParams) {
        return typeof value.blocked == "undefined";
      });

      dispatch(posts_load_success(postsData));
    } catch (error) {
      dispatch(posts_load_failure());
    }
  }
}

/**
 * Get posts description
 * @param title
 * @returns {function(...[*]=)}
 */
export function getPostDescription(title: string) {
  return async (dispatch: any) => {
    dispatch(posts_description_load());

    try {
      const response = await fetch(getPostUrl + title);
      const data = await response.json();
      dispatch(posts_load_success(data));
    } catch (error) {
      dispatch(posts_load_failure());
    }
  }
}


