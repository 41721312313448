import React, { useEffect } from "react";
import "./BreakingNews.scss";
import Time from "../Time/Time";
import Marquee from "react-marquee";
import AddThis from "../AddThis/AddThis";
import { getWidgetsData, widgetsSelector } from '../../slices/widgetsSlice'
import { IBreakingNewsProps } from "./IBreakingNews";
import { IPostServerProperties } from "../Post/IPost";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Typewriter from "typewriter-effect";

function BreakingNews({ lang }: IBreakingNewsProps) {
    const dispatch = useAppDispatch();
    const { breaking_news_posts, breaking_news_weather } = useAppSelector(widgetsSelector) || [];

    const hebrew = lang === 'hebrew';

    useEffect(() => {
        dispatch(getWidgetsData(lang));
    }, [dispatch]);

    let posts: any, weather;

    if (breaking_news_posts.length > 0) {
        let data = breaking_news_posts;
        let p = breaking_news_posts.slice(0, 10);

        let pFiltered = p.map((el: IPostServerProperties) => {
            return el.title;
        });

        posts = pFiltered;
        weather = breaking_news_weather;
    } else {
        posts = [];
        weather = [];
    }

    // Preparing data for weather
    let weatherString = "";

    for (let i = 0, j = weather.length; i < j; i++) {
        // Temperature
        weatherString += `\xa0\xa0\xa0\xa0\xa0\xa0\xa0${weather[i]["name"]} : ${hebrew ? 'טמפרטורת אוויר' : 'Температура воздуха'}:`;
        weatherString += ` ${parseInt(weather[i]["main"]["temp"])}° - ${weather[i]["weather"][0]["description"]
            }`;

        // Pressure
        weatherString += `\xa0\xa0\xa0\xa0\xa0\xa0\xa0${hebrew ? 'לחץ אטמוספרי' : 'Атмосферное давление'}:`;
        weatherString += ` ${parseInt(weather[i]["main"]["pressure"])} ${hebrew ? 'mmHg' : 'мм.рт.ст.'}`;

        // Humidity
        weatherString += `\xa0\xa0\xa0\xa0\xa0\xa0\xa0${hebrew ? 'לחות' : 'Влажность'}:`;
        weatherString += ` ${parseInt(weather[i]["main"]["humidity"])}%`;

        // Wind speed
        weatherString += `\xa0\xa0\xa0\xa0\xa0\xa0\xa0${hebrew ? 'מהירות הרוח' : 'Скорость ветра'}:`;
        weatherString += ` ${parseInt(weather[i]["wind"]["speed"])} ${hebrew ? 'msec' : 'м.сек.'}`;
    }

    return (
        <div className={`b-news ${hebrew ? 'rtl' : ''} animate__animated animate__lightSpeedInRight`}>
            <div className="top-side flip-card">
                <div className="left-part flip-card-inner">

                    <div className="flip-card-front">
                        <div className="title">LIVE</div>
                        <div className="sub-title">NEWS</div>
                    </div>

                    <div className="flip-card-back">
                        <div className="title">LIVE</div>
                        <div className="sub-title">NEWS</div>
                    </div>

                </div>
                <div className="right-part">
                    <div className="top-header">{hebrew ? 'בפועל בשעה האחרונה' : 'АКТУАЛЬНО ЗА ПОСЛЕДНИЙ ЧАС'}</div>
                    <div className="title">
                        {posts && posts.length > 0 && <Typewriter
                            options={{
                                strings: posts,
                                autoStart: true,
                                loop: true,
                                delay: 15,
                                deleteSpeed: 15,
                                cursor: '|',
                            }}
                            />}
                    </div>
                </div>
            </div>
            <div className="bottom-side">
                <div className="time">
                    <Time locale="ru-RU" />
                </div>
                <div className="last-news">
                    <Marquee hoverToStop="true" loop="true" text={weatherString} />
                </div>
            </div>
            <AddThis />
        </div>
    );
}

export default BreakingNews;
