import React, {useState} from "react";
import "./Main.scss";
import PostsWrapper from "../PostsWrapper/PostsWrapper";
import Footer from "../Footer/Footer";
import BreakingNews from "../BreakingNews/BreakingNews";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";

const description =
  "Ежеминутные обновления новостей на русском языке об Израиле. Мы охватываем самый большой русскоязычный новостной сегмент. Ежедневное посещение более 1 миллиона человек.";

const title = "Новости Израиля. Ежеминутные обновления на русском языке.";

const MainPage = () => {
  const language = "russian";

  return (
    <div className="Main">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>


      <Header lang={language} />

      <PostsWrapper lang={language} />

      <Footer />

      <BreakingNews lang={language} />
    </div>
  );
};

export default MainPage;
