import {useEffect} from "react";
import "./PostsWrapper.scss";
import Post from "../Post/Post";
import Loader from "../Loader/Loader";
import {getMainPosts, getMainHebrewPosts, postsSelector} from '../../slices/postsSlice'
import {IPostWrapperProps} from "./IPostsWrapper";
import {IPostParams} from "../Post/IPost";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

function PostsWrapper({lang}: IPostWrapperProps) {
  const dispatch = useAppDispatch();
  const {posts} = useAppSelector(postsSelector) || [];
  useEffect(() => {
    let postWrapper: any;

    if (lang === 'russian') {
      postWrapper = getMainPosts;
    } else if (lang === 'hebrew') {
      postWrapper = getMainHebrewPosts;
    }

    const interval = setInterval(() => {
      dispatch(postWrapper());
    }, 100);

    return () => {
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, [dispatch, lang]);

  return (
    <div className="posts-wrapper">
      <Loader active={posts.length <= 0}/>

      {typeof posts != "undefined" &&
      posts.map((post: IPostParams) => (
        post.title !== "" && <Post
          key={post._id}
          id={post._id}
          title={post.title}
          rating={post.rating}
          link={post.link}
          owner={post.owner}
          lang={lang}
          date={post.serverDateOfPublication}
        />
      ))}
    </div>
  );
}

export default PostsWrapper;
