import React from "react";
import "./Header.scss";
import Currencies from "../Currencies/Currencies";
import {IHeaderProps} from "./IHeader";
import DarkMode from "../DarkMode/DarkMode";

function Header({ lang }: IHeaderProps) {
  const urlLang = lang === "hebrew" ? "he" : "";
  const href =
    lang === "hebrew"
      ? "https://t.me/news_israel_he"
      : "https://t.me/news_israel_ru";

  return (
    <header className={lang === "hebrew" ? "rtl" : "" + "animate__animated animate__lightSpeedInLeft"}>
      <a href={"https://news.rubinchyk.com/" + urlLang} className="logo"></a>
      <a
        href={
          lang === "hebrew"
            ? "https://news.rubinchyk.com"
            : "https://news.rubinchyk.com/he"
        }
        className="language"
      >
        {lang === "hebrew" ? "RU" : "HE"}
      </a>
      <a rel="noopener noreferrer" href={href} target="_blank">
        <div className="telegram-icon"></div>
      </a>
      <DarkMode/>
      <Currencies />
    </header>
  );
}

export default Header;
